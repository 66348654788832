.main-row {
    max-height: calc(100%);
    min-height: calc(100%);
}

.filter-bar {
    background-color: #5C798C;
    border-radius: 15px;
    padding: 20px 20px 20px 17px;
    margin: 10px 10px;
    min-height: calc(100% - 20px);
}

.graph-screen-wrapper {
    padding: 10px;
}

.graph-screen {
    background-color: #BDD1E0;
    border-radius: 15px;
    padding: 20px;
}

.dashboard-inline {
    display: block;
}
.dashboard-inline > a {
    text-decoration: none;
    color: inherit;
}
.dashboard-inline > a> div {
    display: inline-block;
    margin: 0px 10px;
    font-size: 23px;
    padding: 10px;
    background-color: #0000001F;
}

.dashboard-box {
    background-color: #BDD1E0;
    border-radius: 15px;
    padding: 20px;
    margin: 10px 10px;
    display: block;
}

.dashboard-box > div {
    font-size: 28px;
    text-align: center;
}

.dashboard-description {
    text-align: center;
    color: #FFFFFF8F;
    margin-top: 10px;
}
.dashboard-list {
    margin: 20px 0px;
}

.dashboard-list > a {
    text-decoration: none;
    color: inherit;
}

.dashboard-button {
    background-color: #FFFFFF1F;
    margin: 6px 0px;
    padding: 5px 3px;
    border-radius: 7px;
    text-align: center;
    cursor: pointer;
}
.dashboard-btn {
    text-decoration: none;
    height: inherit;
    background-color: #FFFFFF1F;
    width: 100%;
    box-shadow: none;
    border: none;
    color: inherit;
    margin: 6px 0px;
    padding: 5px 3px;
    margin: 6px 0px;
    padding: 5px 3px;
    border-radius: 7px;
    text-align: center;
    font-family: inherit;
    font-size: inherit;
    cursor: pointer;
}

.col-edit-wrapper {
    margin: 10px 0px;
    background-color: #5C798C;
    border: 1px solid #0000001a;
    border-radius: 15px;
    padding: 0px 10px 15px 10px;
    box-shadow: 3px 3px 2px 0px #00000040;
    
}

.col-edit-form-label {
    display: block;
}

.col-edit-form-label-inline {
    display: inline;
}

.dash-edit-form-label {
    display: block;
}

.dash-edit-form-label-inline {
    display: inline;
}

.graph-screen-button {
    background-color: #0000001F;
    margin: 6px 0px;
    padding: 5px 3px;
    border-radius: 7px;
    text-align: center;
    cursor: pointer;
}

.graph-screen-submit {
    background-color: #FE5000;
    margin: 6px 0px;
    padding: 5px 3px;
    border-radius: 7px;
    text-align: center;
    cursor: pointer;
}

.col-edit-right {
    float: right;
}

.col-edit-left {
    float: left;
}

.col-edit-button {
    cursor: pointer;
    padding: 5px;
    margin:5px;
}
.active-board {
    border: 2px solid white;
}

.board-title {
    margin-top: 2px;
    color: black;
}

.dashboard-chart-wrapper {
    padding: 10px;
}

.dashboard-chart {
    padding: 20px;
    background-color: #BDD1E0;
    border-radius: 15px;
}

.chart-screen-wrapper {
    padding: 0px;
}

body {
    overflow-y: scroll;
}