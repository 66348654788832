body {
  color: #F7F7F9;
}

.App {
  /* width: calc(100vw - 20px); */
  min-height: calc(100vh - 20px);
  background-color: #f9f7f7;
  padding: 10px;

}


@media (min-width: 1200px) {
  .app-header {
    height: 100px !important;
  }

  .app-content {
    height: calc(100% - 150px) !important;
    min-height: calc(100vh - 70px);
  }

  .side-bar {
    min-height: calc(100vh - 170px);
    height: calc(100vh - 170px);
    margin-top: 0px !important;
  }
}

.app-header {
  height: 200px;
}

.app-content {
  height: 100%;
  min-height: calc(100vh - 170px);
}

.app-footer {
  height: 50px;
}

.side-bar {
  min-height: 20%;
  margin-top: 50px;
}

.loading-box {
  width: 500px;
  height: 200px;
  text-align: center;
  margin: auto;
  color: #5C798C;
  font-size: 30px;
  margin-top: calc(25% - 30px);


  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;

  animation-name: fadeInDown;
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-40px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.gridjs-wrapper::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 10px;
  background-color: #5C798C;
  border-radius: 1px;
}

.gridjs-wrapper::-webkit-scrollbar-thumb {
  background: #FE5000;
  width: 8px;
  border-radius: 1px;
}

.gridjs-wrapper::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
