@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500&display=swap');

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* CSS selectors to quickly assign styling to text */

.font-light {
  font-weight: 300;
}

.font-regular {
  font-weight: 400;
}

.font-heavy {
  font-weight: 500;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.center {
  text-align: center;
  margin: 0 auto;
}

.justify {
  text-align: justify;
}

.text-dark {
  color: black;
}

.text-light {
  color: white;
}

/* CSS Selectors to quickly do margin*/

.mt-0 {
  margin-top: 0em !important;
}

.mt-1 {
  margin-top: 1em !important;
}

.mt-2 {
  margin-top: 2em !important;
}

.mt-3 {
  margin-top: 3em !important;
}

.mb-0 {
  margin-bottom: 0em !important;
}

.mb-1 {
  margin-bottom: 1em !important;
}

.mb-2 {
  margin-bottom: 2em !important;
}

.mb-3 {
  margin-bottom: 3em !important;
}

.p-0 {
  padding: 0em !important;
}

.p-1 {
  padding: 1em !important;
}

.p-2 {
  padding: 2em !important;
}