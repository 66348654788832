.bar-wrapper {
    height: 120px;
    padding: 20px;
    background-color: #5C798C;
    border-radius: 15px;
    margin: 20px 10px;
}

.bar-items {
    height: 100%;
    font-weight: 100;
    font-size: 20px;
    margin: 5px 0px;
}

@media (min-width: 1200px) {
    .bar-wrapper {
        height: 60px;
        padding: 15px 20px;
        background-color: #5C798C;
        border-radius: 15px;
        margin: 20px 10px !important;
    }

    .bar-items {
        height: 100%;
        font-weight: 100;
        font-size: 20px;
    }
}


.image-icon {
    margin-top: -20px;
    height: 140px;
}

.image-wrapper {
    height: 100px;
}

.router-link {
    text-decoration: none;
    color: white;
}

.router-link::hover {
    color: green;
}