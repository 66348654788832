.main-row {
    max-height: calc(100%);
    min-height: calc(100%);
}

.filter-bar {
    background-color: #5C798C;
    border-radius: 15px;
    padding: 20px 20px 20px 17px;
    margin: 10px 10px;
    height: calc(100% - 20px);
}

.login-box-wrapper {
    background-color: #BDD1E0;
    height: 500px;
    border-radius: 15px;
    padding: 20px;
    margin: 10px 10px;
    height: calc(100% - 20px);
}

.login-error {
    color: #FE5000;
    font-size: 15px;
    font-weight: 500;
    margin: 20px 1px;
}

.login-box {
    margin: auto;
    margin-top: calc(25% - 90px);
    padding: 10px;
    width: 350px;
}

.login-label {
    margin: 10px 0 5px 0;
    font-size: 15px;
    color: #5C798C;
}

.login-input {
    margin: 5px 0 10px 0;
    padding: 8px 5px;
    border: 2px solid #5C798C;
    border-radius: 9px;
    box-sizing: border-box;
    width: 100%;
}

.login-button {
    margin: 10px 0 0 0;
    padding: 7px 10px;
    border: 1px solid #5C798C;
    border-radius: 9px;
    background: #5C798C;
    width: 100%;
    font-size: 15px;
    color: #f9f7f7;
    display: block;
    text-decoration: none;
    text-align: center;
    height: 35px;
    cursor: pointer;
}

.overview-box {
    width: 600px;
}

.forgot-password-box {
    margin: auto;
    padding: 10px;
    width: 350px;
    margin-top: 90px;
}

.forgot-password-button {
    margin: 10px 0 0 0;
    padding: 7px 10px;
    border: 1px solid #5C798C;
    border-radius: 9px;
    background: #5C798C;
    width: 100%;
    font-size: 15px;
    color: #f9f7f7;
    display: block;
    text-decoration: none;
    text-align: center;
    height: 35px;
    cursor: pointer;
}

.dashboard-button {
    text-decoration: none;
    color: #f9f7f7;
}

.no-link {
    text-decoration: none;
}