.config-section {
    width: 100%;
    margin-bottom: 1em !important;
    border-left: 3px solid #f9f7f7;
    padding-left: 10px;
    color: #5C798C;
}

.user-list-item {
    list-style: none;
    background-color: #fff;
    border: 1px solid #f9f7f7;
    box-shadow: 10px 10px 10px #00000033;
    padding: 5px;
    border-radius: 10px;
    margin: 5px 0px;
    cursor: pointer;
    margin-right: 20px;
}

.user-list-item-name {
    font-size: 16px;
    font-weight: bold;
}

.user-config-section {
    margin-top: 5px;
}

.user-list-wrapper {
    padding-left: 0px;
    height: 400px;
    padding: 5px;
    margin-right: 50px;
    overflow-y: auto;
    padding-bottom: 20px;
}

.user-delete {
    padding: 5px;
    border-radius: 9px;
    border: 1px solid white;
}

.clear-both {
    clear: both;
}

.user-selected {
    background-color: #e1f1f1;
}

.color-red {
    color: red;
}

.black-text {
    color: black;
}

.blue {
    color: #5C798C;
}

.gridjs-wrapper {
    overflow-y: scroll;
}