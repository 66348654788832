.options-section {
    /* width: calc(100% / 3 * 2); */
    color: black;
    border-radius: 15px;
    padding: 10px 20px;
    margin: 0;
    margin-bottom: 20px;
    background-color: #BDD1E0;
}

.right-options-section {
    margin: 0;
    margin-bottom: 20px;
}

.section-header {
    margin: 0px;
}

.search-button {
    margin: 10px 0 0 0;
    padding: 7px 10px;
    border: 1px solid #5C798C;
    border-radius: 9px;
    background: #5C798C;
    width: 20%;
    font-size: 15px;
    color: #f9f7f7;
    display: inline-block;
    text-decoration: none;
    text-align: center;
    height: 35px;
    cursor: pointer;
}

.search-input {
    margin: 5px 0 10px 0;
    padding: 8px 5px;
    border: 2px solid #5C798C;
    border-radius: 9px;
    box-sizing: border-box;
    width: 75%;
    display: inline-block;
    margin-right: 5px;
}

.search-wrapper {
    display: inline-block;
    width: 100%;
}

.search-error {
    color: #FE5000;
    font-size: 15px;
    font-weight: 500;
    margin: 5px 0px;
}

.empty-wrapper {
    height: 500px;
    border-radius: 15px;
    margin: 10px 10px;
    height: calc(100% - 20px);
}

.data-summery {
    color: black;
    height: 100%;
    border-radius: 15px;
    padding: 10px 20px;
    margin: 0;
    margin-bottom: 20px;
    margin-left: 20px;
    background-color: #BDD1E0;
}

.data-wrapper {
    color: black;
}

.no-options {
    text-align: center;
    margin: 20px 0px;
}

.gridjs-column-wrapper {
    padding: 5px;
}

.dual-config-row {
    padding-right: 0px;
}

@media (min-width: 1200px) {
    .dual-config-row {
        padding-right: 20px !important;
        height: 100%
    }

    .dual-config-row:last-child {
        padding-right: 0px !important;
    }
}

.suggestions-item {
    color: darkblue;
    list-style: none;
    margin: 6px 0px;
}

.suggestion-box {
    background-color: white;
    border: 2px solid #5C798C;
    border-top: none;
    width: 75%;
    border-bottom-left-radius: 9px;
    border-bottom-right-radius: 9px;
    padding: 0 5px;
    margin: 0;
    font-size: 14px;
    font-family: sans-serif;
}

.suggestion-seperator {
    list-style: none;
    height: 2px;
    border: 1px solid dimgray;
    margin-top: 5px;
    border-radius: 5px;
    background-color: dimgray;
}

.force-capital {
    text-transform: uppercase;
}

.suggestion-nothing-found {
    list-style: none;
    margin-top: 3px;
    margin-bottom: 3px;
}

.suggestions-target {
    position: absolute;
    text-align: left;
    z-index: 3;
    cursor: default;
    -webkit-user-select: none;
    width: 100%;
    margin-top: -25px;
}